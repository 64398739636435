import React, { useState, useEffect } from 'react';
import {
  Space,
  Row,
  Col,
  Button,
  Spin,
  Form,
  Input,
  Upload,
  message,
  Collapse,
  notification,
  Modal,
  InputNumber,
  Select,
} from 'antd';
import Icon, {
  RightOutlined,
  LoadingOutlined,
  EyeOutlined,
  ArrowLeftOutlined,
  UpOutlined,
  DownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
// import DatePicker from "react-datepicker";
import axios from 'axios';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BASE_API_URL, BASE_URL } from 'services/config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../index.scss';
// import { Editor } from '@tinymce/tinymce-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';
import { downloadFile } from 'utils';

import { currencyFormatter, currencyFormatterSpecial } from 'utils';

import { ReactComponent as Confirmation } from '../../../assets/logo/Confirmation.svg';
import HistoryReviewModal from 'components/modal/HistoryReview';
import ProposalModal from 'components/modal/ProposalNew';

const HomeLined = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DownloadAbleIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="32" height="32" rx="16" fill="#D6FFE8" />
    <path
      d="M19.3327 11.5126V14.2663C19.3327 14.6397 19.3327 14.8264 19.4053 14.969C19.4693 15.0944 19.5712 15.1964 19.6967 15.2603C19.8393 15.333 20.026 15.333 20.3993 15.333H23.153M23.3327 16.6585V21.4663C23.3327 22.5864 23.3327 23.1465 23.1147 23.5743C22.9229 23.9506 22.617 24.2566 22.2407 24.4484C21.8128 24.6663 21.2528 24.6663 20.1327 24.6663H15.866C14.7459 24.6663 14.1859 24.6663 13.758 24.4484C13.3817 24.2566 13.0757 23.9506 12.884 23.5743C12.666 23.1465 12.666 22.5864 12.666 21.4663V14.533C12.666 13.4129 12.666 12.8529 12.884 12.425C13.0757 12.0487 13.3817 11.7427 13.758 11.551C14.1859 11.333 14.7459 11.333 15.866 11.333H18.0072C18.4964 11.333 18.741 11.333 18.9711 11.3883C19.1752 11.4373 19.3703 11.5181 19.5493 11.6277C19.7511 11.7514 19.924 11.9244 20.2699 12.2703L22.3954 14.3957C22.7413 14.7417 22.9143 14.9146 23.038 15.1164C23.1476 15.2954 23.2284 15.4905 23.2774 15.6945C23.3327 15.9247 23.3327 16.1693 23.3327 16.6585Z"
      stroke="#00BD52"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="2"
      y="2"
      width="32"
      height="32"
      rx="16"
      stroke="#EEFFF4"
      stroke-width="4"
    />
  </svg>
);

const UploadIcon = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
    <path
      d="M19.667 26.3333L23.0003 23M23.0003 23L26.3337 26.3333M23.0003 23V30.5M29.667 26.9524C30.6849 26.1117 31.3337 24.8399 31.3337 23.4167C31.3337 20.8854 29.2816 18.8333 26.7503 18.8333C26.5682 18.8333 26.3979 18.7383 26.3054 18.5814C25.2187 16.7374 23.2124 15.5 20.917 15.5C17.4652 15.5 14.667 18.2982 14.667 21.75C14.667 23.4718 15.3632 25.0309 16.4894 26.1613"
      stroke="#475467"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F9FAFB"
      stroke-width="6"
    />
  </svg>
);

const EditProposalSubmissionDirut = () => {
  const params = useParams();
  let navigate = useNavigate();
  const { Dragger } = Upload;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const [dataProposal, setDataProposal] = useState();
  const [loading, setLoading] = useState(true);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [isReject, setIsReject] = useState(false);
  const [rejectTitle, setRejectTitle] = useState('');

  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [modalProposal, setModalProposal] = useState(false);
  const [logSection, setLogSection] = useState('');

  const queryParams = new URLSearchParams(window.location.search);
  const role = queryParams.get('role');
  const group = queryParams.get('group');

  let proposal = {};

  const [revisionDocuments, setRevisionDocuments] = useState([]);
  const props = {
    name: 'file',
    multiple: true,
    action: BASE_API_URL + '/api/file-service/v1/files',
    beforeUpload: (file) => {
      const isTypeAllowed =
        file.type === 'application/pdf' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/svg' ||
        file.type === 'image/png';
      if (!isTypeAllowed) {
        // message.error(`${file.name} is not a pdf file`);
        notification.error({
          message: 'Kesalahan',
          description: `Dokumen yang dapat di-upload adalah Dokumen PDF/JPG/JPEG/SVG/PNG`,
        });
      }

      const isSizeAllowed = file.size <= 100 * 1024 * 1024;
      if (!isSizeAllowed) {
        notification.error({
          message: 'Kesalahan',
          description: `Ukuran Dokumen yang di Upload Melebihi 100MB`,
        });
      }

      return (isTypeAllowed && isSizeAllowed) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);

        console.log(info.file, info.fileList);
        setRevisionDocuments(info.fileList);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const modules = {
    toolbar: false,
  };

  useEffect(() => {
    loadDetailDataProposal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentReviewer, setCurrentReviewer] = useState();

  const handleFunding = (data) => {
    switch (data.currency) {
      case 'IDR':
        return `Rp ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      case 'EURO':
        return `€ ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      case 'USD':
        return `$ ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      case 'JPY':
        return `¥ ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      default:
        return `${data.currency} ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
    }
  };

  const loadDetailDataProposal = () => {
    axios
      .get(`${BASE_URL}/proposal/${params.id}?role=${role}&group=${group}`)
      .then((res) => {
        proposal = res.data.result;
        setLoading(false);
        form.setFieldsValue({
          generalInformation: proposal.generalInformation,
        });
        form.setFieldsValue({ donor: proposal.donor });
        form.setFieldsValue({ director: proposal.director });
        form.setFieldsValue({ contactPerson: proposal.contactPerson });
        form.setFieldsValue({ documents: proposal.documents });
        proposal.submissionFunding.funding = handleFunding(
          proposal.submissionFunding
        );
        proposal.submissionFunding.dp =
          moment(proposal?.submissionFunding?.date_period).format(
            'DD-MM-YYYY'
          ) +
          ' s/d ' +
          moment(proposal?.submissionFunding?.end_date_period).format(
            'DD-MM-YYYY'
          );
        form.setFieldsValue({ submissionFunding: proposal.submissionFunding });
        form.setFieldsValue({ distribution: proposal.distribution });
        form.setFieldsValue({ priorityPrograms: proposal.priorityPrograms });
        form.setFieldsValue({
          projectPriorityPrograms: proposal.projectPriorityPrograms,
        });
        form.setFieldsValue({ klDirectorate: proposal.klDirectorate });
        form.setFieldsValue({
          investmentCriteria: proposal.investmentCriteria,
        });
        form.setFieldsValue({
          financialInstrument: proposal.financialInstrument,
        });
        form.setFieldsValue({
          organizationBackground: proposal.organizationBackground,
        });
        form.setFieldsValue({ needs: proposal.needs });
        form.setFieldsValue({
          organizationalLandscape: proposal.organizationalLandscape,
        });
        form.setFieldsValue({ principleObstacle: proposal.principleObstacle });
        form.setFieldsValue({
          communicatingResult: proposal.communicatingResult,
        });
        form.setFieldsValue({ projectStructure: proposal.projectStructure });
        form.setFieldsValue({ safeguards: proposal.safeguards });
        form.setFieldsValue({ others: proposal.others });

        form.setFieldsValue({ addendum: proposal.addendum });
        form.setFieldsValue({ parentAddendumId: proposal.parentAddendumId });
        form.setFieldsValue({ status: proposal.status });
        form.setFieldsValue({ review: proposal.review });
        form.setFieldsValue({ draft: proposal.draft });
        setDataProposal(form.getFieldsValue(true));
        setCurrentReviewer(proposal.currentReviewer);

        console.log('current reviewer', proposal.currentReviewer);
      })
      .catch((err) => {
        notification.error({
          message: 'Kesalahan',
          description: err.message,
        });
      });
  };

  const revisionButton = () => (
    <Button
      className="btn-rev-donor"
      type="default"
      onClick={(event) => {
        onRejectProposal('Revisi dari Donor');
      }}
      disabled={currentReviewer?.group !== 'donor'}
    >
      Revisi dari Donor
    </Button>
  );

  const approvalButton = () => (
    <Button
      className="btn-default"
      type="default"
      onClick={(event) => {
        onApproval('donor');
      }}
      disabled={currentReviewer?.group !== 'donor'}
    >
      Proposal Disetujui
    </Button>
  );

  const itemsCollapse = [
    {
      label: 'Validasi Approval Proposal',
      showArrow: false,
      extra: (
        <div className="flex gap-4">{[revisionButton(), approvalButton()]}</div>
      ),
    },
  ];

  const handlePanelChange = (panelKey) => {
    const expanded = [...expandedPanels];
    const index = expanded.indexOf(panelKey);

    if (index > -1) {
      expanded.splice(index, 1);
    } else {
      expanded.push(panelKey);
    }

    setExpandedPanels(expanded);
  };

  const expandIcon = ({ isActive }) => {
    if (isActive) {
      return <DownOutlined style={{ fontSize: 14 }} />;
    }
    return <UpOutlined style={{ fontSize: 14 }} />;
  };

  function onApprovalProposal() {
    setLoading(true);
    const payload = {
      approve: true,
      sendReviewTo: null,
      rejectReason: '',
    };
    axios
      .put(
        `${BASE_URL}/proposal/${params.id}/approval?role=${role}&group=${group}`,
        payload
      )
      .then((res) => {
        setLoading(false);
        if (res) {
          notification.success({
            message: `Proposal berhasil disetujui!`,
            duration: '5000',
            description: 'Data yang anda masukan sudah berhasil disetujui.',
          });
          return navigate('/proposal-submission');
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Kesalahan',
          duration: '1000',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  const onApproval = (donor) => {
    Modal.confirm({
      title: '',
      okText: 'Confirm',
      icon: <Confirmation />,
      centered: true,
      bodyStyle: { justifyContent: 'center' },
      content: (
        <>
          <h1
            className=""
            style={{ fontSize: '17px', fontWeight: '600', lineHeight: '24px' }}
          >
            {donor === 'donor'
              ? 'Apakah Proposal ini disetujui oleh Donor?'
              : 'Apakah Anda yakin akan menyetujui Proposal ini?'}
          </h1>
          <br />
        </>
      ),
      onOk() {
        onApprovalProposal();
      },
    });
  };

  const onRejectProposal = (title) => {
    if (title === 'Revisi dari Donor') {
      setRejectTitle('Revisi dari Donor');
    } else {
      setRejectTitle('Tolak Proposal');
    }
    setIsReject(true);
  };

  const onModalaRejectProposal = () => {
    setIsReject(false);
  };

  function onProposalRejected() {
    setLoading(true);
    const rejectReason = form.getFieldsValue('reason').reason;
    const payload = {
      approve: false,
      rejectReason: rejectReason,
    };

    if (rejectTitle === 'Revisi dari Donor') {
      payload.sendReviewTo = 'presidentDirector';
    }

    if (revisionDocuments?.name) {
      console.log(revisionDocuments);
      payload.rejectFile = {
        fileId: 'fileId',
        fileName: revisionDocuments.name,
        fileSize: revisionDocuments.size,
        url: revisionDocuments.url,
      };
    }

    if (!rejectReason) {
      notification.error({
        message: 'Kesalahan',
        duration: 5,
        description: 'Alasan ditolak wajib diisi',
      });
      setLoading(false);
    } else {
      axios
        .put(
          `${BASE_URL}/proposal/${params.id}/approval?role=${role}&group=${group}`,
          payload
        )
        .then((res) => {
          setLoading(false);
          if (res) {
            notification.success({
              message: 'Proposal Berhasil Ditolak',
              duration: '5000',
              description: 'Data yang anda masukan sudah berhasil ditolak.',
            });
          }
          form.resetFields();
          return navigate('/proposal-submission');
        })
        .catch((err) => {
          notification.error({
            message: 'Kesalahan',
            duration: '1000',
            description: err.response.data.message,
          });
          setLoading(false);
        });
    }
  }

  const handleStatus = (value) => {
    switch (value?.status) {
      case 'review':
        let text = [];
        value?.review?.groups.forEach((group) => {
          text.push(
            <span
              className="px-4 py-1"
              style={{
                background: '#EEFFF4',
                color: '#01A24A',
                borderRadius: '15px',
              }}
            >
              {group}
            </span>
          );
        });
        return <>{text.map((item) => item)}</>;
      case 'draft':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#EEFFF4',
              color: '#01A24A',
              borderRadius: '15px',
            }}
          >
            Draft
          </span>
        );
      case 'rejected':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#FEF3F2',
              color: '#B42318',
              borderRadius: '15px',
            }}
          >
            Ditolak
          </span>
        );
      case 'approved':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#EEFFF4',
              color: '#01A24A',
              borderRadius: '15px',
            }}
          >
            Disetujui
          </span>
        );
      default:
    }
  };

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <Form
          form={form}
          name="form-edit-proposal"
          // onFinish={onFinish}
          initialValues={{
            priorityPrograms: [{}],
            projectPriorityPrograms: [{}],
            outputs: [{}],
          }}
        >
          <div className="px-4">
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLined} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mt-2 mr-3">
                  Kelola Proposal Submission
                </p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex justify-center items-center mt-2"
                >
                  <span className="font-medium text-center">
                    Detail Proposal Submission
                  </span>
                </Button>
              </div>
            </div>
            <div
              className="overflow-hidden mb-4 mt-2"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-heading">Informasi Donor</p>
              </div>
              <hr />
              <div className="px-5 mt-4 mb-4">
                <Row className="gap-4">
                  <Col span={4}>
                    <div className="mb-4">
                      <p className="text-sub-heading">Nama Donor</p>
                      <span className="text-body">
                        {(dataProposal?.donor[0]
                          ? dataProposal?.donor[0].donor
                          : dataProposal?.donor.donor) || '-'}
                      </span>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="mb-4">
                      <p className="text-sub-heading">Program Tematik</p>
                      {dataProposal?.donor.sectors
                        ? dataProposal?.donor.sectors.map((sector) => (
                            <ul className="text-body">
                              <span className="text-body">
                                {sector.name || '-'}
                              </span>
                            </ul>
                          ))
                        : '-'}
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="mb-4">
                      <p className="text-sub-heading">No Telepon</p>
                      {dataProposal?.donor.phoneNumbers
                        ? dataProposal?.donor.phoneNumbers.map(
                            (phoneNumber) => (
                              <ul className="text-body">
                                <span className="text-body">
                                  {phoneNumber || '-'}
                                </span>
                              </ul>
                            )
                          )
                        : '-'}
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="mb-4">
                      <p className="text-sub-heading">Email</p>
                      {dataProposal?.donor.email
                        ? dataProposal?.donor.email.map((email) => (
                            <ul className="text-body">
                              <span className="text-body">{email || '-'}</span>
                            </ul>
                          ))
                        : '-'}
                    </div>
                  </Col>
                  <Col span={4}>
                    <div>
                      <p className="text-sub-heading">Website</p>
                      <span className="text-body">
                        {(dataProposal?.donor[0]
                          ? dataProposal?.donor[0].website
                          : dataProposal?.donor?.website) || '-'}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr className="mb-6 mt-4" />
            </div>

            <div
              className="overflow-hidden mb-4 mt-2"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <Row>
                <Col span={15}>
                  <div className="flex gap-4 px-5 py-5">
                    <p className="text-heading">Data Proposal</p>

                    <div className="mt-1 mb-4">
                      {handleStatus(dataProposal)}
                    </div>
                  </div>
                </Col>
                <Col span={9}>
                  <div className="flex gap-4 justify-end items-center mr-4 mt-3">
                    <Button
                      className="btn-default"
                      onClick={(event) => {
                        event.stopPropagation();

                        setOpenModalHistory(true);
                      }}
                    >
                      Histori Review & Approval
                    </Button>
                    <Button
                      className="button-style"
                      icon={<EyeOutlined style={{ fontSize: 18 }} />}
                      style={{
                        width: '160px',
                        height: '44px',
                        border: '1px solid #00BD52',
                        background: '#00BD52',
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        fontWeight: 600,
                      }}
                      onClick={() => setModalProposal(true)}
                    >
                      Lihat Proposal
                    </Button>
                  </div>
                </Col>
              </Row>
              <hr />
              <Collapse
                className="mt-4 px-5 pb-4"
                items={itemsCollapse}
              ></Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel className="px-5 pb-4" header="Lampiran Dokumen" key="1">
                  <div className="px-4">
                    <div className="flex mt-4 mb-4">
                      {dataProposal?.documents &&
                        dataProposal?.documents.map((doc, key) => (
                          <div
                            className="flex justify-between mr-4"
                            style={{
                              border: '1px solid #EAECF0',
                              borderRadius: '12px',
                              padding: '16px',
                              width: '335px',
                              height: '96px',
                            }}
                          >
                            <div className="mr-4">
                              <Icon component={DownloadAbleIcon} />
                            </div>
                            <div className="row mr-10">
                              <p className="wrap-text bold">{doc.fileName} </p>{' '}
                              <span>({doc.fileSize}KB)</span>
                              <div className="flex justify-between">
                                <strong
                                  style={{
                                    color: '#00BD52',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    window.open(doc.url, '_blank');
                                  }}
                                >
                                  Buka
                                </strong>
                                <strong
                                  style={{
                                    color: '#00BD52',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    downloadFile(doc.url, doc.fileName);
                                  }}
                                >
                                  Unduh
                                </strong>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4 p-0"
              >
                <Panel
                  className="px-5 pb-4 custom-panel"
                  header="Informasi General"
                  key="1"
                >
                  <div
                    style={{ border: '1px solid #E4E4E4', background: '#FFFF' }}
                    className="px-4 rounded-lg"
                  >
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pt-2 pb-2"
                    >
                      <Col span={8}>
                        <div className="text-sub-body">Judul Proposal</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.title || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">
                          Kementrian Lembaga/Negara
                        </div>
                        <ul
                          className="text-body ml-4"
                          style={{ listStyleType: 'disc' }}
                        >
                          {dataProposal?.klDirectorate?.kl?.map((kl) => (
                            <li>{kl.klName}</li>
                          ))}
                        </ul>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Direktorat</div>
                        <ul
                          className="text-body ml-4"
                          style={{ listStyleType: 'disc' }}
                        >
                          {dataProposal?.klDirectorate?.directorate?.map(
                            (directorate) => (
                              <li>{directorate.directorateName}</li>
                            )
                          )}
                        </ul>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pt-2 pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body">Objektif Proposal</div>
                        {dataProposal?.generalInformation?.objectives ? (
                          dataProposal?.generalInformation?.objectives?.map(
                            (objective, idx) => (
                              <div className="text-body">
                                {idx + 1}. {objective}
                              </div>
                            )
                          )
                        ) : (
                          <div className="text-body">-</div>
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={8}>
                        <div className="text-sub-body">Nama Organisasi</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.organizationName ||
                            '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Negara</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.country || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Provinsi</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.province || '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={8}>
                        <div className="text-sub-body">Kota/Kabupaten</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.city || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Kecamatan</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.district || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Kelurahan</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.subdistrict || '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={8}>
                        <div className="text-sub-body">Kode Pos</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.zipcode || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">No Telepon</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.phoneNumber || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Email</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.email || '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={8}>
                        <div className="text-sub-body">Fax</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.fax || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Website</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.website || '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body">Alamat</div>
                        <div className="text-body">
                          {dataProposal?.generalInformation?.address || '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body !text-lg pt-1">
                          Informasi Direktur
                        </div>
                      </Col>
                    </Row>
                    {dataProposal?.director.map((director) => (
                      <Row
                        style={{ borderBottom: '1px solid #E4E4E4' }}
                        className="pb-2"
                      >
                        <Col span={8}>
                          <div className="text-sub-body">Nama Direktur</div>
                          <div className="text-body">
                            {director?.name || '-'}
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="text-sub-body">Jabatan</div>
                          <div className="text-body">
                            {director?.position || '-'}
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="text-sub-body">Email</div>
                          <div className="text-body">
                            {director?.email || '-'}
                          </div>
                        </Col>
                      </Row>
                    ))}
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body !text-lg pt-1">
                          Informasi Narahubung
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={8}>
                        <div className="text-sub-body">Nama Narahubung</div>
                        <div className="text-body">
                          {dataProposal?.contactPerson?.name || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Email</div>
                        <div className="text-body">
                          {dataProposal?.contactPerson?.email || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">No. Telepon</div>
                        <div className="text-body">
                          {dataProposal?.contactPerson?.phoneNumber || '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={8}>
                        <div className="text-sub-body">Fax</div>
                        <div className="text-body">
                          {dataProposal?.contactPerson?.fax || '-'}
                        </div>
                      </Col>
                      <Col span={16}>
                        <div className="text-sub-body">Alamat</div>
                        <div className="text-body">
                          {dataProposal?.contactPerson?.address || '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body !text-lg pt-1">
                          Dana Pengajuan
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pb-2"
                    >
                      <Col span={8}>
                        <div className="text-sub-body">Total Dana Program</div>
                        <div className="text-body">
                          {dataProposal?.submissionFunding?.funding || '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">
                          Periode Pelaksanaan Program
                        </div>
                        <div className="text-body">
                          {dataProposal?.submissionFunding?.dp
                            ? dataProposal?.submissionFunding?.dp
                            : '-'}
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="text-sub-body">Jangka Waktu Hibah</div>
                        <div className="text-body">
                          {dataProposal?.submissionFunding?.grant_period +
                            ' ' +
                            dataProposal?.submissionFunding
                              ?.grant_period_type || '-'}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />
              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="px-5 custom-panel"
                  header="Program Prioritas"
                  key="1"
                >
                  <div
                    style={{ border: '1px solid #E4E4E4' }}
                    className="bg-white rounded-lg pb-5"
                  >
                    {dataProposal?.priorityPrograms &&
                      dataProposal?.priorityPrograms.map((program, key) => (
                        <Collapse
                          expandIcon={expandIcon}
                          defaultActiveKey={['1']}
                          expandIconPosition="end"
                          onChange={handlePanelChange}
                          className="mt-4"
                        >
                          <Panel
                            className="px-5 custom-panel"
                            header={`Program Prioritas #${key + 1}`}
                            key="1"
                          >
                            <div
                              style={{ border: '1px solid #E4E4E4' }}
                              className="bg-white rounded-lg pb-5"
                            >
                              <Row
                                style={{ borderBottom: '1px solid #E4E4E4' }}
                                className="px-5"
                              >
                                <Col span={4}>
                                  <div className="text-sub-body">
                                    Program Tematik:
                                  </div>
                                </Col>
                                <Col span={20}>
                                  <div className="text-body">
                                    {program.thematicProgrammes}
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                style={{ borderBottom: '1px solid #E4E4E4' }}
                                className="px-5"
                              >
                                <Col span={4}>
                                  <div className="text-sub-body">
                                    Tujuan Program:
                                  </div>
                                </Col>
                                <Col span={20}>
                                  <div className="text-body">
                                    {program.programGoals}
                                  </div>
                                </Col>
                              </Row>

                              <Row
                                style={{ background: '#FFF' }}
                                className="px-5 py-5"
                              >
                                {program.impact && (
                                  <Col
                                    span={24}
                                    style={{
                                      background: '#EEFFF4',
                                      border: '1px solid #E4E4E4',
                                      marginBottom: 10,
                                    }}
                                  >
                                    <Row
                                      className="px-5"
                                      style={{ background: '#FFF' }}
                                    >
                                      <Col span={4}>
                                        <div className="text-sub-body">
                                          Impact
                                        </div>
                                      </Col>
                                      <Col span={20}>
                                        <div className="text-body">
                                          {program.impact.impactName}
                                        </div>
                                      </Col>
                                    </Row>

                                    {program.impact.indicators &&
                                      program.impact.indicators.map(
                                        (indicator) => (
                                          <Row
                                            className="px-5"
                                            style={{
                                              background: '#FFF',
                                              borderBottom: '1px solid #E4E4E4',
                                            }}
                                          >
                                            <Col span={4}>
                                              <div className="text-sub-body">
                                                Indicator
                                              </div>
                                            </Col>
                                            <Col span={20}>
                                              <div className="text-body">
                                                {indicator.indicatorName}
                                              </div>
                                            </Col>
                                          </Row>
                                        )
                                      )}

                                    <Row
                                      style={{ background: '#FFF' }}
                                      className="px-5 pt-5"
                                    >
                                      {program.impact.outcomes &&
                                        program.impact.outcomes.map(
                                          (outcome) => (
                                            <Col
                                              span={24}
                                              style={{
                                                background: '#EEFFF4',
                                                border: '1px solid #E4E4E4',
                                                marginBottom: 10,
                                              }}
                                            >
                                              <Row className="px-5">
                                                <Col span={4}>
                                                  <div className="text-sub-body">
                                                    Outcome
                                                  </div>
                                                </Col>
                                                <Col span={20}>
                                                  <div className="text-body">
                                                    {outcome.outcomeName}
                                                  </div>
                                                </Col>
                                              </Row>

                                              {outcome.indicators &&
                                                outcome.indicators.map(
                                                  (indicator) => (
                                                    <Row
                                                      className="px-5"
                                                      style={{
                                                        borderBottom:
                                                          '1px solid #E4E4E4',
                                                      }}
                                                    >
                                                      <Col span={4}>
                                                        <div className="text-sub-body">
                                                          Indicator
                                                        </div>
                                                      </Col>
                                                      <Col span={20}>
                                                        <div className="text-body">
                                                          {
                                                            indicator.indicatorName
                                                          }
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  )
                                                )}

                                              {outcome.outputs &&
                                                outcome.outputs.map(
                                                  (output) => (
                                                    <Col
                                                      span={24}
                                                      style={{
                                                        background: '#EEFFF4',
                                                        marginBottom: 10,
                                                      }}
                                                    >
                                                      <Row className="px-5">
                                                        <Col span={4}>
                                                          <div className="text-sub-body">
                                                            Output
                                                          </div>
                                                        </Col>
                                                        <Col span={20}>
                                                          <div className="text-body">
                                                            {output.outputName}
                                                          </div>
                                                        </Col>
                                                      </Row>

                                                      {output.subOutputs &&
                                                        output.subOutputs.map(
                                                          (subOutput) => (
                                                            <Col
                                                              span={24}
                                                              style={{
                                                                background:
                                                                  '#EEFFF4',
                                                                marginBottom: 10,
                                                              }}
                                                            >
                                                              <Row className="px-5">
                                                                <Col span={4}>
                                                                  <div className="text-sub-body">
                                                                    Sub Output
                                                                  </div>
                                                                </Col>
                                                                <Col span={20}>
                                                                  <div className="text-body">
                                                                    {
                                                                      subOutput.subOutputName
                                                                    }
                                                                  </div>
                                                                </Col>
                                                              </Row>

                                                              {subOutput.indicators &&
                                                                subOutput.indicators.map(
                                                                  (
                                                                    indicator
                                                                  ) => (
                                                                    <Row
                                                                      className="px-5"
                                                                      style={{
                                                                        borderBottom:
                                                                          '1px solid #E4E4E4',
                                                                      }}
                                                                    >
                                                                      <Col
                                                                        span={4}
                                                                      >
                                                                        <div className="text-sub-body">
                                                                          Indicator
                                                                        </div>
                                                                      </Col>
                                                                      <Col
                                                                        span={
                                                                          20
                                                                        }
                                                                      >
                                                                        <div className="text-body">
                                                                          {
                                                                            indicator.indicatorName
                                                                          }
                                                                        </div>
                                                                      </Col>
                                                                    </Row>
                                                                  )
                                                                )}
                                                            </Col>
                                                          )
                                                        )}
                                                    </Col>
                                                  )
                                                )}
                                            </Col>
                                          )
                                        )}
                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </Panel>
                        </Collapse>
                      ))}
                  </div>
                </Panel>
              </Collapse>
              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="px-5 custom-panel"
                  header="Organization Background (IEF)"
                  key="1"
                >
                  <div className="">
                    <div className="">
                      <Form.Item name="organizationBackground">
                        <ReactQuill
                          className="rounded-lg"
                          modules={modules}
                          theme="snow"
                          readOnly
                          style={{ background: '#FFFF', cursor: 'not-allowed' }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel className="px-5 custom-panel" header="Needs" key="1">
                  <div className="">
                    <div className="">
                      <Form.Item name="needs">
                        <ReactQuill
                          className="rounded-lg"
                          modules={modules}
                          theme="snow"
                          readOnly
                          style={{ background: '#FFFF', cursor: 'not-allowed' }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="px-5 custom-panel"
                  header="Project Prioritas"
                  key="1"
                >
                  <div
                    style={{ border: '1px solid #E4E4E4' }}
                    className="bg-white rounded-lg pb-5"
                  >
                    {dataProposal?.projectPriorityPrograms &&
                      dataProposal?.projectPriorityPrograms.map(
                        (program, key) => (
                          <Collapse
                            expandIcon={expandIcon}
                            defaultActiveKey={['1']}
                            expandIconPosition="end"
                            onChange={handlePanelChange}
                            className="mt-4"
                          >
                            <Panel
                              className="px-5 custom-panel"
                              header={`Project Prioritas #${key + 1}`}
                              key="1"
                            >
                              <div
                                style={{ border: '1px solid #E4E4E4' }}
                                className="bg-white rounded-lg pb-5"
                              >
                                <Row
                                  style={{ borderBottom: '1px solid #E4E4E4' }}
                                  className="px-5"
                                >
                                  <Col span={4}>
                                    <div className="text-sub-body">
                                      Tujuan Project:
                                    </div>
                                  </Col>
                                  <Col span={20}>
                                    <div className="text-body">
                                      {program.programGoals}
                                    </div>
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    borderBottom: '1px solid #E4E4E4',
                                    background: '#FFF',
                                  }}
                                  className="px-5 py-5"
                                >
                                  {program.outcomes &&
                                    program.outcomes.map(
                                      (outcome, keyOutcome) => (
                                        <Col
                                          span={24}
                                          style={{
                                            background: '#EEFFF4',
                                            border: '1px solid #E4E4E4',
                                            marginBottom: 10,
                                          }}
                                        >
                                          <Row
                                            className="px-5"
                                            style={{ background: '#FFF' }}
                                          >
                                            <Col span={4}>
                                              <div className="text-sub-body">
                                                Outcome
                                              </div>
                                            </Col>
                                            <Col span={20}>
                                              <div className="text-body">
                                                {outcome.projectOutcome}
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row
                                            className="px-5"
                                            style={{
                                              background: '#FFF',
                                              borderBottom: '1px solid #E4E4E4',
                                            }}
                                          >
                                            <Col span={4}>
                                              <div className="text-sub-body">
                                                Linkage Output BPDLH
                                              </div>
                                            </Col>
                                            <Col span={20}>
                                              <div className="text-body">
                                                {
                                                  outcome.linkageOutput
                                                    ?.outputName
                                                }
                                              </div>
                                            </Col>
                                          </Row>

                                          <Row
                                            style={{
                                              borderBottom: '1px solid #E4E4E4',
                                              background: '#EEFFF4',
                                            }}
                                            className="px-5"
                                          >
                                            {outcome.outputs &&
                                              outcome.outputs.map(
                                                (output, keyOutcome) => (
                                                  <Col
                                                    span={24}
                                                    style={{
                                                      background: '#EEFFF4',
                                                      borderBottom:
                                                        '1px solid #E4E4E4',
                                                    }}
                                                  >
                                                    <Row className="px-5">
                                                      <Col span={4}>
                                                        <div className="text-sub-body">
                                                          Output
                                                        </div>
                                                      </Col>
                                                      <Col span={20}>
                                                        <div className="text-body">
                                                          {output.projectOutput}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row className="px-5">
                                                      <Col span={4}>
                                                        <div className="text-sub-body">
                                                          Linkage Sub Output
                                                          BPDLH
                                                        </div>
                                                      </Col>
                                                      <Col span={20}>
                                                        <div className="text-body">
                                                          {output
                                                            .linkageSubOutput
                                                            ?.subOutputName ||
                                                            '-'}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row className="px-5">
                                                      <Col span={4}>
                                                        <div className="text-sub-body">
                                                          Alokasi Kebutuhan Dana
                                                          (Rp)
                                                        </div>
                                                      </Col>
                                                      <Col span={20}>
                                                        <div className="text-body">
                                                          {currencyFormatter(
                                                            output.budgetAllocation ||
                                                              0
                                                          )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                )
                                              )}
                                          </Row>
                                        </Col>
                                      )
                                    )}
                                </Row>

                                <Row
                                  className="mx-5 mb-5 px-5 py-5"
                                  style={{ background: '#EEFFF4' }}
                                >
                                  <Col span={24}>
                                    <Row gutter={8}>
                                      <Col span={8}>
                                        <label className="font-medium">
                                          Total Kebutuhan Dana (Rp)
                                        </label>
                                        <Form.Item>
                                          <InputNumber
                                            placeholder="Total Kebutuhan Dana (Rp)"
                                            className="form-input mt-2 w-full"
                                            style={{
                                              height: '47px',
                                              width: '100%',
                                              textAlign: 'left',
                                            }}
                                            controls={false}
                                            value={currencyFormatterSpecial(
                                              program.totalAmountRp || 0
                                            )}
                                            disabled
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={5}>
                                        <label className="font-medium">
                                          Nilai Kurs
                                        </label>
                                        <Form.Item>
                                          <InputNumber
                                            placeholder="Kurs"
                                            className="form-input mt-2 w-full"
                                            style={{
                                              height: '47px',
                                              width: '100%',
                                              textAlign: 'left',
                                            }}
                                            controls={false}
                                            value={currencyFormatter(
                                              program.exchangeRate || 0
                                            )}
                                            defaultValue={1}
                                            disabled
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={3}>
                                        <label className="font-medium">
                                          Mata Uang
                                        </label>
                                        <Form.Item>
                                          <Select
                                            showSearch
                                            placeholder="Mata Uang"
                                            className="mt-2"
                                            type="text"
                                            defaultValue={'IDR'}
                                            // tagRender={tagRender}
                                            style={{ width: '100%' }}
                                            value={program.currency}
                                            disabled
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={8}>
                                        <label className="font-medium">
                                          Total Nilai Kebutuhan Dana (
                                          {program.currency})
                                        </label>
                                        <Form.Item>
                                          <InputNumber
                                            placeholder="Total Nilai Kebutuhan Dana"
                                            className="form-input mt-2 w-full"
                                            style={{
                                              height: '47px',
                                              width: '100%',
                                              textAlign: 'left',
                                            }}
                                            controls={false}
                                            value={currencyFormatterSpecial(
                                              program.totalAmount || 0
                                            )}
                                            disabled
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </Panel>
                          </Collapse>
                        )
                      )}
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pt-2 pb-2 px-5"
                    >
                      <Col span={24}>
                        <div className="text-sub-body">Investment Criteria</div>
                        {dataProposal?.investmentCriteria ? (
                          dataProposal?.investmentCriteria.map((item) => (
                            <div className="text-body">{item}</div>
                          ))
                        ) : (
                          <div className="text-body">{'-'}</div>
                        )}
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pt-2 pb-2 px-5"
                    >
                      <Col span={24}>
                        <div className="text-sub-body">
                          Financial Instrument
                        </div>
                        {dataProposal?.financialInstrument ? (
                          dataProposal?.financialInstrument.map((item) => (
                            <div className="text-body">{item}</div>
                          ))
                        ) : (
                          <div className="text-body">{'-'}</div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="px-5 custom-panel"
                  header="Organizational Landscape"
                  key="1"
                >
                  <div className="">
                    <Form.Item name="organizationalLandscape">
                      <ReactQuill
                        className="rounded-lg"
                        modules={modules}
                        theme="snow"
                        readOnly
                        style={{ background: '#FFFF', cursor: 'not-allowed' }}
                      />
                    </Form.Item>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4 p-0"
              >
                <Panel
                  className="px-5 pb-4 custom-panel"
                  header="Penyaluran"
                  key="1"
                >
                  <div
                    style={{ border: '1px solid #E4E4E4', background: '#FFFF' }}
                    className="px-4 rounded-lg"
                  >
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pt-2 pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body">
                          Mekanisme Alokasi (Budget Allocation & Call of
                          Proposal)
                        </div>
                        <div className="text-body">
                          {dataProposal?.distribution?.allocationMechanism ||
                            '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pt-2 pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body">
                          Mekanisme Penyaluran (Direct Access & Intermediate)
                        </div>
                        <div className="text-body">
                          {dataProposal?.distribution?.distributionMechanism ||
                            '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pt-2 pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body">Lokasi (Provinsi)</div>
                        <div className="text-body">
                          {dataProposal?.distribution?.location || '-'}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{ borderBottom: '1px solid #E4E4E4' }}
                      className="pt-2 pb-2"
                    >
                      <Col span={24}>
                        <div className="text-sub-body">Penerima Manfaat</div>
                        <div className="text-body">
                          {dataProposal?.distribution?.beneficiary || '-'}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="px-5 custom-panel"
                  header="Risk Management"
                  key="1"
                >
                  <div className="">
                    <div className="">
                      <Form.Item name="principleObstacle">
                        <ReactQuill
                          className="rounded-lg"
                          modules={modules}
                          theme="snow"
                          readOnly
                          style={{ background: '#FFFF', cursor: 'not-allowed' }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="px-5 custom-panel"
                  header="Communication Strategy"
                  key="1"
                >
                  <div className="">
                    <div className="">
                      <Form.Item name="communicatingResult">
                        <ReactQuill
                          className="rounded-lg"
                          modules={modules}
                          theme="snow"
                          readOnly
                          style={{ background: '#FFFF', cursor: 'not-allowed' }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="px-5 custom-panel"
                  header="Project Management Structure"
                  key="1"
                >
                  <div className="">
                    <div className="">
                      <Form.Item name="projectStructure">
                        <ReactQuill
                          className="rounded-lg"
                          modules={modules}
                          theme="snow"
                          readOnly
                          style={{ background: '#FFFF', cursor: 'not-allowed' }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="px-5 custom-panel"
                  header="Safeguards"
                  key="1"
                >
                  <div className="">
                    <div className="">
                      <Form.Item name="safeguards">
                        <ReactQuill
                          className="rounded-lg"
                          modules={modules}
                          theme="snow"
                          readOnly
                          style={{ background: '#FFFF', cursor: 'not-allowed' }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4 mb-8"
              >
                <Panel className="px-5 custom-panel" header="Others" key="1">
                  <div className="">
                    <div className="">
                      <Form.Item name="others">
                        <ReactQuill
                          className="rounded-lg"
                          modules={modules}
                          theme="snow"
                          readOnly
                          style={{ background: '#FFFF', cursor: 'not-allowed' }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div
              className="mt-6 px-4"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
                position: 'fixed',
                bottom: 0,
                width: 'calc(100vw - 400px)',
              }}
            >
              <div className="flex justify-between items-center">
                <Space>
                  <NavLink to={'/proposal-submission'}>
                    <Button
                      style={{
                        borderRadius: '8px',
                        border: '1px solid #EAECF0',
                        width: '240px',
                        height: '50px',
                      }}
                      icon={<ArrowLeftOutlined />}
                      className="flex justify-center items-center font-medium"
                    >
                      <span className="ml-2 text-center">Kembali</span>
                    </Button>
                  </NavLink>
                </Space>
                <div className="row">
                  <Form.Item>
                    <Space>
                      <Button
                        icon={<CloseCircleOutlined />}
                        className="flex justify-center items-center font-medium mt-6 ml-4 btn-danger"
                        onClick={onRejectProposal}
                        disabled={
                          currentReviewer?.group !== 'presidentDirector'
                        }
                      >
                        <span className="ml-2 text-center">Tolak Proposal</span>
                      </Button>
                      <Button
                        icon={<CheckCircleOutlined />}
                        className="flex justify-center items-center font-medium mt-6 ml-4 btn-approve"
                        onClick={onApproval}
                        disabled={
                          currentReviewer?.group !== 'presidentDirector'
                        }
                      >
                        <span className="ml-2 text-center">
                          Setujui Proposal
                        </span>
                      </Button>
                    </Space>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <Modal
            closable={false}
            title={rejectTitle}
            open={isReject}
            width={700}
            footer={[
              <div className="flex justify-center mt-6">
                <Button
                  onClick={onModalaRejectProposal}
                  size="large"
                  className="font-medium"
                  style={{ width: '290px' }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="font-medium"
                  style={{ width: '290px' }}
                  onClick={onProposalRejected}
                >
                  Confirm
                </Button>
              </div>,
            ]}
          >
            <div className="mt-4">
              <div>
                <label className="font-medium mt-4">Upload Dokumen</label>{' '}
                <br />
                <span className="" style={{ color: '#828282' }}>
                  *Bisa upload lebih dari satu
                </span>
                <div className="row mt-4 mb-4">
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon flex justify-center">
                      {/* <InboxOutlined /> */}
                      <UploadIcon />
                    </p>
                    <p className="ant-upload-text">
                      <strong style={{ color: '#01A24A' }}>
                        Klik untuk upload
                      </strong>{' '}
                      atau drag and drop
                    </p>
                    <p className="ant-upload-hint">
                      SVG, PNG, JPG or GIF (max.100mb)
                    </p>
                  </Dragger>
                </div>
              </div>

              <label className="field-required font-medium mt-4">Alasan</label>
              <Form.Item name="reason">
                <Input.TextArea
                  placeholder="Alasan"
                  type="text"
                  className="form-input mt-4 mb-4"
                />
              </Form.Item>
            </div>
          </Modal>

          <HistoryReviewModal
            idProposal={params.id}
            section={logSection}
            openModal={openModalHistory}
            setOpenModal={setOpenModalHistory}
          />

          <ProposalModal
            idProposal={params.id}
            openModal={modalProposal}
            setOpenModal={setModalProposal}
            proposal={dataProposal}
          />
        </Form>
      </Spin>
    </>
  );
};

export default EditProposalSubmissionDirut;
