import React, { useState, useEffect } from "react";
import { Form, Button, Space, Modal, notification, Row, Col, message, Upload, Input, Select, InputNumber } from 'antd';
import '../index.scss'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import Icon, { RightOutlined } from '@ant-design/icons';

import { BASE_API_URL, BASE_URL } from 'services/config';
import Dragger from "antd/es/upload/Dragger";
import { DeleteIcon, DownloadAbleIcon, UploadIcon } from "components/atoms/SVG";
import { currencyFormatter, currencyParser, downloadFile } from "utils";

import { ReactComponent as Confirmation } from "../../../assets/logo/Confirmation.svg"
import InfiniteScrollSelect from "components/molecules/InfiniteScrollSelect";

const HomeLine = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const CreateRekening = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const [officialMemo, setOfficialMemo] = useState();

  const [listProposal, setListProposal] = useState([]);
  const [loading, setLoading] = useState(false);

  // const queryParams = new URLSearchParams(window.location.search);
  // const role = queryParams.get('role');
  // const group = queryParams.get('group');

  const requiredField = [{ required: true, message: 'Wajib Diisi' }];

  const getListProposal = (page, limit, search) => {
    let apiPath = `${BASE_URL}/bank-account/list-dropdown?page=${page}&limit=${limit}`;

    if (search) {
      apiPath = `${BASE_URL}/bank-account/list-dropdown?page=${page}&limit=${limit}&search=${search}`;
    }

    return axios.get(apiPath)
      .then((res) => {
        let result = res.data.result;

        let arr = [];
        if (result && result.length > 0) {
          result.forEach((item) => {
            arr.push({ label: item.generalInformation?.title, value: item.id });
          });
        }

        return arr; // Return the data as a resolved promise
      })
      .catch((err) => {
        notification.error({
          message: "Kesalahan",
          description: err.message
        });
      })
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async (search) => {
    setLoading(true);

    try {
      let newListProposal;

      if (search) {
        newListProposal = await getListProposal(parseInt(listProposal.length / 100) + 1, 100, search);

        // Check if there is more data to load
        setHasMoreData(newListProposal.length >= 100);

        setListProposal([...newListProposal]);
      } else {
        newListProposal = await getListProposal(parseInt(listProposal.length / 100) + 1, 100);

        // Check if there is more data to load
        setHasMoreData(newListProposal.length >= 100);

        setListProposal([...listProposal, ...newListProposal]);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const debouncedFetchData = debounce(fetchData, 700);

  const normalizePayload = (data) => {
    let payload = {
      ...data,
      officialMemo: officialMemo
    }

    return payload;
  }

  const onFinish = (values) => {
    const payload = normalizePayload(values);

    Modal.confirm({
      title: '',
      icon: <Confirmation />,
      okText: "Confirm",
      bodyStyle: { justifyContent: "center" },
      content: (
        <>
          <h1
            className=""
            style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px" }}
          >
            Apakah Anda yakin akan menyimpan data ini?
          </h1>
          <br />
        </>
      ),
      onOk() {
        setLoading(true);
        axios.post(`${BASE_URL}/bank-account`, payload)
          .catch((err) => {
            let errorMessage;
            let errorMessages = [];

            if (err.response.data.errors === undefined || err.response.data.errors.length === 0) {
              errorMessage = err.response.data.message;
            } else {
              <ul style={{ listStyleType: "disc" }}>
                {
                  err.response.data.errors.forEach((error) => {
                    errorMessages.push(<li>&#x2022; {error.message}</li>);
                  })
                }
              </ul>
            }
            notification.error({
              message: 'Kesalahan',
              duration: 5,
              description: errorMessage ? errorMessage : errorMessages
            });
            setLoading(false);
          })
          .then((res) => {
            setLoading(false);
            if (res) {
              onDone();
            }
          });
      }
    });
  };

  const onDone = () => {
    Modal.success({
      title: '',
      okText: "Confirm",
      content: (
        <>
          <h1
            className=""
            style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", textTransform: "capitalize" }}
          >
            Data berhasil disimpan!
          </h1>
          <br />
        </>
      ),
      onOk() {
        return navigate("/rekening");
      }
    })
  };

  const handleDeleteDocument = (docType) => {
    Modal.confirm({
      centered: true,
      title: '',
      icon: <Confirmation />,
      okText: "Confirm",
      bodyStyle: { justifyContent: "center" },
      content: (
        <>
          <h1 className="mb-4" style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", justifyContent: "center" }}>
            Anda yakin menghapus dokumen ini?
          </h1>
        </>
      ),
      onOk() {
        setOfficialMemo(null);
      }
    })
  }

  const propsAdditional = {
    name: 'file',
    // multiple: true,
    action: BASE_API_URL + '/api/file-service/v1/files',
    beforeUpload: (file) => {
      console.log(file)
      const isTypeAllowed = (file.type === "application/pdf" || file.type === "application/docx" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png");
      if (!isTypeAllowed) {
        // message.error(`${file.name} is not a pdf file`);
        notification.error({
          message: "Kesalahan",
          description: `Dokumen yang dapat di-upload adalah Dokumen PDF/DOCX/JPG/PNG`
        });
      }

      const isSizeAllowed = file.size <= 10 * 1024 * 1024;
      if (!isSizeAllowed) {
        notification.error({
          message: "Kesalahan",
          description: `Ukuran Dokumen yang di Upload Melebihi 10MB`
        });
      }

      return (isTypeAllowed && isSizeAllowed) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        let doc = info.file.response?.data
        let _officialMemo = {
          "fileId": doc.id,
          "fileName": doc.name,
          "fileSize": doc.size,
          "mimeType": doc.mimeType,
          "path": doc.key
        }
        setOfficialMemo(_officialMemo)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <>
      <div className="px-4">
        <Form
          form={form}
          name="form-create-rekening"
          onFinish={onFinish}
          initialValues={{
            pic: [{}],
            sectors: [{}]
          }}
        >
          <div className="flex items-center py-5 flex-wrap">
            <Space>
              <Icon component={HomeLine} className="mr-2" />
              <RightOutlined
                className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Daftar Rekening</p>
            </div>
            <Space>
              <RightOutlined
                className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <Button
                style={{ background: "#EEFFF4", color: "#01A24A", borderRadius: "8px", border: "none" }}
                className="flex justify-center items-center"
              >
                <span className="font-medium text-center">{(params.id !== "new") ? "Detail" : "Create"}</span>
              </Button>

            </div>
          </div>

          <div className="w-100 list overflow-hidden mb-6" style={{ borderRadius: "8px", border: "1px solid #EAECF0" }}>
            <div className="">
              <div className="" style={{ background: "#00BD52", height: "10px" }}></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Ajukan Pembuatan Rekening</p>
              </div>
              <hr />
              <div className="px-5 mt-4">
                <Row gutter={12}>
                  <Col span={12}>
                    <label className="col-4 field-required font-medium">Proposal</label>
                    <Form.Item
                      name="proposalId"
                      rules={requiredField}
                    >
                      {/* <InfiniteScrollSelect
                        showSearch
                        placeholder="Proposal"
                        className="mt-2"
                        listHeight={128}
                        style={{ width: '100%' }}
                        data={listProposal}
                        hasMore={listProposal.length / 100 > 100 ? true : false}
                        loadMore={fetchData}
                        onChange={(value, option) => {
                          form.setFieldValue("contract", option?.contract?.number || "-")
                        }}
                        onSearch={(newValue) => debouncedFetchData(newValue)}
                      /> */}
                      <InfiniteScrollSelect
                        showSearch
                        placeholder="Proposal"
                        className="mt-2"
                        listHeight={128}
                        style={{ width: '100%' }}
                        data={listProposal}
                        hasMore={hasMoreData}  // Use a separate state variable for more accurate tracking
                        loadMore={() => fetchData(searchTerm)}  // Pass the search term to fetchData
                        onChange={(value, option) => {
                          form.setFieldValue("contract", option?.contract?.number || "-")
                        }}
                        onSearch={(newValue) => {
                          setHasMoreData(true);  // Reset hasMoreData when a new search starts
                          setSearchTerm(newValue);
                          debouncedFetchData(newValue);
                        }}

                        onBlur={() => debouncedFetchData(searchTerm)}
                      />

                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label className="col-4 font-medium">Nomor Kontrak</label>
                    <Form.Item
                      name="contract"
                    >
                      <Input
                        placeholder="Nomor Kontrak"
                        type="text"
                        className="form-input mt-2"
                        style={{ width: "100%" }}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="px-5 gap-x-6">
                <Row gutter={12}>
                  <Col span={12}>
                    <label className="col-4 field-required font-medium">Mata Uang</label>
                    <Form.Item
                      name="currency"
                      rules={requiredField}
                    >
                      <Select
                        showSearch
                        placeholder="Mata Uang"
                        className="mt-2"
                        type="text"
                        // tagRender={tagRender}
                        style={{ width: "100%" }}
                        options={[
                          { label: "IDR", value: "IDR" },
                          { label: "EURO", value: "EURO" },
                          { label: "POUNDS", value: "POUNDS" },
                          { label: "USD", value: "USD" },
                          { label: "AUD", value: "AUD" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label className="col-4 field-required font-medium">Nama Bank</label>
                    <Form.Item
                      name="bankName"
                      rules={requiredField}
                    >
                      <Select
                        showSearch
                        placeholder="Nama Bank"
                        className="mt-2"
                        type="text"
                        // tagRender={tagRender}
                        style={{ width: "100%" }}
                        options={[
                          { label: "Bank BRI", value: "Bank BRI" },
                          { label: "Bank Mandiri", value: "Bank Mandiri" },
                          { label: "Bank BNI", value: "Bank BNI" },
                          { label: "Bank BTN", value: "Bank BTN" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="px-5 gap-x-6">
                <Row gutter={12}>
                  <Col span={12}>

                    <label className="field-required font-medium">Jumlah Komitmen </label>
                    <Form.Item
                      name="amount"
                      rules={requiredField}
                    >
                      <InputNumber
                        placeholder="Jumlah Komitmen"
                        className="form-input mt-2 w-full"
                        style={{ height: "47px", width: "100%", textAlign: "left" }}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label className="field-required font-medium">Periode Project</label>
                    <Row gutter={12}>
                      <Col span={12}>
                        <Form.Item
                          name="period"
                          rules={requiredField}
                        >
                          <Input
                            placeholder="Periode Project"
                            type="text"
                            className="form-input mt-2"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="periodType"
                        >
                          <Input
                            placeholder="Bulan"
                            type="text"
                            defaultValue={"Bulan"}
                            value="Bulan"
                            className="form-input mt-2"
                            style={{ width: "100%" }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="px-5 gap-x-6">
                <Row>
                  <Col span={24}>
                    <label className="col-4 field-required font-medium">Upload Dokumen Nota Dinas</label>
                    {officialMemo ?
                      <div className="flex justify-between mr-4 mt-2" style={{ border: "1px solid #EAECF0", borderRadius: "12px", padding: "16px", width: "335px", height: "96px" }}>
                        <div className="mr-4">
                          <Icon component={DownloadAbleIcon} />
                        </div>
                        <div className="row mr-10">
                          <p className="wrap-text bold">{officialMemo.fileName} </p> <span>({officialMemo.fileSize}KB)</span>
                          {officialMemo.url &&
                            <div className="flex justify-between">
                              <strong
                                style={{ color: "#00BD52", cursor: "pointer" }}
                                onClick={() => { window.open(officialMemo.url, '_blank') }}
                              >
                                Buka
                              </strong>
                              <strong
                                style={{ color: "#00BD52", cursor: "pointer" }}
                                onClick={() => { downloadFile(officialMemo.url, officialMemo.fileName) }}
                              >
                                Unduh
                              </strong>
                            </div>
                          }
                        </div>
                        <div className="ml-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => { handleDeleteDocument("officialMemo") }}
                        >
                          <Icon component={DeleteIcon} />
                        </div>
                      </div>
                      :
                      <Dragger {...propsAdditional} className="mt-2">
                        <p className="ant-upload-drag-icon flex justify-center">
                          <UploadIcon />
                        </p>
                        <p className="ant-upload-text"><strong style={{ color: "#01A24A" }}>Klik untuk upload</strong> atau drag and drop</p>
                        <p className="ant-upload-hint">PNG, JPG, PDF, DOCX (max.10mb)</p>
                      </Dragger>
                    }
                  </Col>
                </Row>
              </div>
              <hr className="mb-4 mt-10" />
            </div>
          </div>

          <div className="list mt-6 px-4" style={{ borderRadius: "8px", border: "1px solid #EAECF0" }}>
            <div className="flex justify-between items-center">
              <Space>
                <NavLink to={'/rekening'}>
                  <Button
                    style={{ borderRadius: "8px", border: "1px solid #EAECF0", width: "120px", height: "50px" }}
                    icon={<ArrowLeftOutlined />}
                    className="flex justify-center items-center font-medium"
                  >
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                </NavLink>
              </Space>
              <Space>
                <Form.Item style={{ marginBottom: 0 }}>
                  {/* <ReachableContext.Provider value="Light"> */}
                  <Button
                    style={{ background: "#00BD52", color: "#FFFF", borderRadius: "8px", width: "120px", height: "50px" }}
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    className="flex justify-center items-center font-medium mt-4 mb-4"
                    disabled={loading}
                    loading={loading}
                  >
                    <span className="ml-2 text-center">Simpan</span>
                  </Button>
                  {/* </ReachableContext.Provider> */}
                </Form.Item>
              </Space>
            </div>
          </div>
        </Form>
      </div >
    </>
  );
}

export default CreateRekening;