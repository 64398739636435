import axios from "axios";
import { BASE_URL } from "services/config";

// const token = localStorage.getItem('token');

// // Set config defaults when creating the instance
// const Axios = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
// });

// Axios.defaults.headers.common.Authorization = `Bearer ${token}`;

export const getDonorList = () => {
  return axios.get(`${BASE_URL}/donor/list`);
};

export const getTotalPenghimpunanDana = () => {
  return axios.get(`${BASE_URL}/dashboard/total-penghimpunan-dana`);
};

export const getPenghimpunanDanaByProposal = async () => {
  return axios.get(`${BASE_URL}/dashboard/penghimpunan-dana-by-proposal`);
};

export const getPenghimpunanDanaByFinancialInstrument = async () => {
  return axios.get(
    `${BASE_URL}/dashboard/penghimpunan-dana-by-financial-instrument`
  );
};

export const getDonorCount = async () => {
  return axios.get(`${BASE_URL}/dashboard/donor-count`);
};

export const getNilaiKomitmenByDonor = async (donorId) => {
  return axios.get(`${BASE_URL}/dashboard/nilai-komitmen-by-donor/${donorId}`);
};

export const getProposalCountByType = async () => {
  return axios.get(`${BASE_URL}/dashboard/proposal-count-by-type`);
};

export const getSubmissionProposalCountByStatus = async () => {
  return axios.get(`${BASE_URL}/dashboard/submission-proposal-count-by-status`);
};

export const getTopDonors = async () => {
  return axios.get(`${BASE_URL}/dashboard/top-donors`);
};
