/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import {
  Space,
  Table,
  Button,
  Input,
  Modal,
  Spin,
  Pagination,
  Select,
  Tag,
  DatePicker,
  Col,
  Row,
} from 'antd';
import Icon, {
  SearchOutlined,
  EyeOutlined,
  RightOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../index.scss';
import { BASE_URL } from 'services/config';
import {
  CalenderIcon,
  GreenDotIcon,
  GreyDotIcon,
  HomeLined,
  LeftArrow,
  OrangeDotIcon,
  RightArrow,
} from 'components/atoms/SVG';

const ListKontrak = () => {
  let navigate = useNavigate();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const [dataProposal, setDataProposal] = useState([]);
  const [loading, setLoading] = useState();
  const [opt, setOpt] = useState(10);
  const [page] = useState(1);
  const [paginator, setPaginator] = useState();
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);

  const [searchKey, setSearchKey] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [sortDir, setSortDir] = useState('descend');
  const [sortBy, setSortBy] = useState();

  const loadDataProposal = () => {
    setLoading(true);
    let path = `${BASE_URL}/contract?page=${current}&limit=${limit}`;

    if (searchKey) {
      path = path + `&search=${searchKey}`;
    }

    if (searchStatus) {
      path = path + `&status=${searchStatus}`;
    }

    if (searchStartDate) {
      path = path + `&startDate=${searchStartDate}`;
    }

    if (searchEndDate) {
      path = path + `&endDate=${searchEndDate}`;
    }

    if (sortDir) {
      path = path + `&sort=${sortDir.replace('end', '')}`;
    }

    if (sortBy) {
      path = path + `&sortBy=${sortBy}`;
    }

    axios
      .get(path)
      .then((res) => {
        setDataProposal(res.data.result);
        setLoading(false);
        setPaginator(res.data.paginator);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useMemo(() => {
    loadDataProposal();
  }, [
    searchKey,
    searchStatus,
    searchStartDate,
    searchEndDate,
    sortDir,
    sortBy,
    current,
    limit,
  ]);

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      setSearchKey(e.target.value);
    }
  };

  const onSearchStatus = (status) => {
    setSearchStatus(status);
  };

  const onSearchStartDate = (date) => {
    setSearchStartDate(date);
  };

  const onSearchEndDate = (date) => {
    setSearchEndDate(date);
  };

  function onPaginationChange(limit) {
    setOpt(limit);
    setLimit(limit);
    setCurrent(1);
  }

  function onNumberPaginationChange(changePage) {
    setCurrent(changePage);
  }

  function onSortChange(_sortDir, _sortBy) {
    setSortBy(_sortBy);
    setSortDir(_sortDir);
  }

  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return (
        <span className="">
          <Icon component={LeftArrow} />
          Previous
        </span>
      );
    }
    if (type === 'next') {
      return (
        <span className="">
          Next
          <Icon component={RightArrow} />
        </span>
      );
    }
    return originalElement;
  }

  function typeList(value) {
    let tags = [];

    switch (value?.contract?.status) {
      case 'unavailable':
        tags.push(
          (tags = {
            colour: '#F2F4F7',
            text: 'Belum Dibuat',
            textColor: '#344054',
            dotColor: GreyDotIcon,
          })
        );
        break;
      case 'assign':
        tags.push(
          (tags = {
            colour: '#F2F4F7',
            text: 'Atur Pengelola Project',
            textColor: '#344054',
            dotColor: GreyDotIcon,
          })
        );
        break;
      case 'inprogress':
        tags.push(
          (tags = {
            colour: '#FFFAEB',
            text: `Sedang Dibuat`,
            textColor: '#B54708',
            dotColor: OrangeDotIcon,
          })
        );
        break;
      case 'done':
        tags.push(
          (tags = {
            colour: '#ECFDF3',
            text: 'Selesai',
            textColor: '#1C7D44',
            dotColor: GreenDotIcon,
          })
        );
        break;
      default:
    }

    if (value.review) {
      return tags.map((value, idx) => {
        return (
          <div className="flex mb-2" style={{ display: idx > 0 && 'none' }}>
            <Tag
              color={value.colour}
              style={{ color: value.textColor }}
              className="flex pl-2 pr-2 items-center"
              icon={<Icon component={value.dotColor} className="" />}
            >
              Sedang Dibuat
            </Tag>
          </div>
        );
      });
    } else {
      return (
        <div className="flex mb-2">
          <Tag
            color={tags.colour}
            style={{ color: tags.textColor }}
            className="flex pl-2 pr-2 items-center"
            icon={<Icon component={tags.dotColor} className="" />}
          >
            {tags.text}
          </Tag>
        </div>
      );
    }
  }

  function onClickDraft(kontrak) {
    return navigate(`/perjanjian/create-kontrak/${kontrak}`);
  }

  function onClickDetail(kontrak) {
    return navigate(`/perjanjian/detail-kontrak/${kontrak}`);
  }

  const [contractSelected, setContractSelected] = useState();
  function assignProject(kontrak) {
    setContractSelected(kontrak);
    setShowModal(true);
  }

  function proposalStatus(value) {
    if (value.contract?.status === 'unavailable') {
      return (
        <div className="status">
          <Button
            className="font-medium"
            type="ghost"
            onClick={() => onClickDraft(value.id)}
            style={{ background: '#01A24A', color: '#FFF' }}
          >
            Upload Kontrak
          </Button>
        </div>
      );
    } else if (value.contract?.status === 'assign') {
      return (
        <div className="status">
          <Button
            className="font-medium"
            type="ghost"
            onClick={() => assignProject(value)}
            style={{ background: '#01A24A', color: '#FFF' }}
          >
            Assign
          </Button>
        </div>
      );
    } else {
      return (
        <div className="status">
          <Button
            type="ghost"
            style={{ border: 'none', marginBottom: '3px' }}
            onClick={() => onClickDetail(value.id)}
          >
            {<EyeOutlined style={{ fontSize: '18px' }} />}
          </Button>
        </div>
      );
    }
  }

  const columns = [
    {
      title: 'No',
      key: 'number',
      width: '68px',
      height: '72px',
      render: (val, text, data) => {
        return <p>{data + 1 + (current - 1) * limit}</p>;
      },
    },
    {
      title: 'ID Proposal',
      key: 'number',
      width: '68px',
      height: '72px',
      sorter: (a, b, sortDir) => {
        onSortChange(sortDir, 'proposalNumber');
      },
      render: (val, text, data) => {
        return (
          <p>
            {val.proposalNumber
              ? val.proposalNumber
              : moment(val.submitDate).format('YYYYMMDDHHmmss')}
            .{val.subProposalNumber}
          </p>
        );
      },
    },
    {
      title: 'Judul Proposal',
      format: 'dd/mm/yyyy',
      width: '400px',
      sorter: (a, b, sortDir) => {
        onSortChange(sortDir, 'generalInformation.title');
      },
      render: (value) => <span>{value.generalInformation?.title}</span>,
    },
    {
      title: 'Nama Donor',
      key: 'donor',
      width: '400px',
      sorter: (a, b, sortDir) => {
        onSortChange(sortDir, 'donor.donor');
      },
      render: (value) => (
        <span>
          {value.donor?.donor || (value.donor ? value.donor[0].donor : '-')}
        </span>
      ),
    },
    {
      title: 'Tanggal Pengajuan',
      key: 'submitDate',
      width: '400px',
      sorter: (a, b, sortDir) => {
        onSortChange(sortDir, 'submitDate');
      },
      render: (value) => (
        <span>
          {value.submitDate
            ? moment(value.submitDate).format('DD/MM/YYYY')
            : '-'}
        </span>
      ),
    },
    {
      title: 'No Kontrak',
      key: 'contract.number',
      width: '400px',
      sorter: (a, b, sortDir) => {
        onSortChange(sortDir, 'contract.number');
      },
      render: (value) => <span>{value.contract?.number || '-'}</span>,
    },
    {
      title: 'Status',
      key: 'contract.status',
      width: '600px',
      sorter: (a, b, sortDir) => {
        onSortChange(sortDir, 'contract.status');
      },
      render: (value) => (
        <>
          <span>{typeList(value)}</span>
        </>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      width: '300px',
      align: 'center',
      render: (value) => proposalStatus(value),
    },
  ];

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        title="Assign Pengelola Project"
        open={showModal}
        onCancel={() => setShowModal(false)}
        width={700}
        footer={[
          <div className="flex justify-center mt-6">
            <Button
              onClick={() => setShowModal(false)}
              size="large"
              className="font-medium"
              style={{ width: '290px' }}
            >
              Batal
            </Button>
            <Button
              type="primary"
              size="large"
              className="font-medium"
              style={{ width: '290px' }}
              onClick={() => setShowModal(false)}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Row gutter={8}>
          <Col span={24}>
            <div className="font-medium">Nama Project</div>
            <Input
              placeholder={contractSelected?.contract?.projectName || '-'}
              type="text"
              className="form-input mt-2"
              disabled
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <div className="font-medium mt-3" style={{ color: '#01A24A' }}>
              Tim Pengelola Project
            </div>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <div className="font-medium">Jabatan</div>
            <Input
              placeholder="Project Director"
              type="text"
              className="form-input mt-2"
              value="Project Director"
              disabled
            />
          </Col>
          <Col span={12}>
            <div className="font-medium">User</div>
            <Select
              showSearch
              placeholder="User"
              className="mt-2"
              type="text"
              defaultValue={'User'}
              options={[
                { value: 'User 1', label: 'User 1' },
                { value: 'User 2', label: 'User 2' },
              ]}
              // tagRender={tagRender}
              style={{ width: '100%' }}
              value={'User'}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <div className="font-medium">Jabatan</div>
            <Input
              placeholder="Project Manager"
              type="text"
              className="form-input mt-2"
              value="Project Manager"
              disabled
            />
          </Col>
          <Col span={12}>
            <div className="font-medium">User</div>
            <Select
              showSearch
              placeholder="User"
              className="mt-2"
              type="text"
              defaultValue={'User'}
              options={[
                { value: 'User 1', label: 'User 1' },
                { value: 'User 2', label: 'User 2' },
              ]}
              // tagRender={tagRender}
              style={{ width: '100%' }}
              value={'User'}
            />
          </Col>
        </Row>
      </Modal>

      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: '#01A24A' }}>
            Perjanjian
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">Perjanjian</h1>
      </div>
      <div
        className="table-list"
        style={{ border: '1px solid #EAECF0', borderRadius: '12px' }}
      >
        <div className="flex gap-4 py-6 px-6">
          <Input
            size="large"
            placeholder="Cari"
            className="mt-2 pl-2"
            prefix={<SearchOutlined />}
            onKeyDown={(e) => onSearch(e)}
            style={{ height: '48px' }}
          />
          {/* <Select
            size="small"
            placeholder="Status"
            allowClear
            type="text"
            className="mt-2"
            style={{ height: "48px", width: '95%' }}
            options={(user.DirectoratCode === "004") ? [{ value: 'unavailable', label: 'Belum Dibuat' }, ...statusList] : statusList}
            onKeyDown={(e) => onSearchStatus(e)}
            onChange={(e) => onSearchStatus(e)}
          /> */}
          <DatePicker
            suffixIcon={<CalenderIcon />}
            placeholder="Start Date"
            className="form-input mt-2"
            prefix={<CalenderIcon />}
            onChange={onSearchStartDate}
            style={{ width: '48%' }}
          >
            <Icon
              component={CalenderIcon}
              className="mr-2"
              style={{ fontSize: 15 }}
            />
          </DatePicker>
          <DatePicker
            suffixIcon={<CalenderIcon />}
            placeholder="End Date"
            className="form-input mt-2"
            prefix={<CalenderIcon />}
            onChange={onSearchEndDate}
            style={{ width: '48%' }}
          >
            <Icon
              component={CalenderIcon}
              className="mr-2"
              style={{ fontSize: 15 }}
            />
          </DatePicker>
        </div>
        <div>
          <Spin spinning={loading} indicator={spinIcon}>
            <Table
              columns={columns}
              dataSource={dataProposal}
              pagination={false}
              onChange={onSearch}
              className="px-5"
              style={{ overflowX: 'auto' }}
            />
            <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
              <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
                <div className="show align-self-center pr-2 font-medium text-sm">
                  {'Tampilkan Data'}
                </div>
                <div className="dropdown-paging align-self-center pr-2">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Select
                      value={opt}
                      className="select-paging-ppd"
                      showArrow={true}
                      style={{ width: '60px' }}
                      options={[
                        { value: 5, label: 5 },
                        { value: 10, label: 10 },
                        { value: 30, label: 30 },
                        { value: 50, label: 50 },
                        { value: 100, label: 100 },
                      ]}
                      onChange={onPaginationChange}
                    />
                  </Space>
                </div>
                <div className="border-paging align-self-center pr-2"></div>
                <div className="total align-self-center pr-2">
                  {`${dataProposal.slice(0, 1).length} - ${
                    dataProposal.length
                  } data dari ${paginator?.itemCount} data`}
                </div>
              </div>
              <div>
                <Pagination
                  pageSize={opt || 10}
                  current={current || page}
                  total={paginator?.itemCount}
                  onChange={onNumberPaginationChange}
                  itemRender={itemRender}
                />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default ListKontrak;
