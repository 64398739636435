import React, { useState } from 'react';
import {
  Form,
  Button,
  Space,
  notification,
  Spin,
  message,
  Upload,
  Modal,
} from 'antd';
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import './index.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Icon, { RightOutlined } from '@ant-design/icons';
import { BASE_API_URL, BASE_URL } from 'services/config';
import { DeleteIcon, DownloadAbleIcon } from 'components/atoms/SVG';
import { ReactComponent as Confirmation } from '../../../../assets/logo/Confirmation.svg';
import { downloadFile } from 'utils';

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UploadIcon = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
    <path
      d="M19.667 26.3333L23.0003 23M23.0003 23L26.3337 26.3333M23.0003 23V30.5M29.667 26.9524C30.6849 26.1117 31.3337 24.8399 31.3337 23.4167C31.3337 20.8854 29.2816 18.8333 26.7503 18.8333C26.5682 18.8333 26.3979 18.7383 26.3054 18.5814C25.2187 16.7374 23.2124 15.5 20.917 15.5C17.4652 15.5 14.667 18.2982 14.667 21.75C14.667 23.4718 15.3632 25.0309 16.4894 26.1613"
      stroke="#475467"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F9FAFB"
      stroke-width="6"
    />
  </svg>
);

const CreateProposalSubmissionStepFour = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const [loading, setLoading] = useState(false);
  const [colDoc, setColDoc] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [documentsError, setDocumentsError] = useState(false);
  const proposalId = params?.id;

  React.useEffect(() => {
    const element = document.getElementById('top');
    element.scrollIntoView();

    loadDetailDataProposal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [proposal, setProposal] = useState();
  const loadDetailDataProposal = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/proposal/${proposalId}`)
      .then((res) => {
        setProposal({
          ...res.data.result,
          documents: res.data.result?.documents ?? [],
        });

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeleteDocument = (idx) => {
    Modal.confirm({
      centered: true,
      title: '',
      icon: <Confirmation />,
      okText: 'Confirm',
      bodyStyle: { justifyContent: 'center' },
      content: (
        <>
          <h1
            className="mb-4"
            style={{
              fontSize: '17px',
              fontWeight: '600',
              lineHeight: '24px',
              justifyContent: 'center',
            }}
          >
            Anda yakin menghapus dokumen ini?
          </h1>
        </>
      ),
      onOk() {
        let arr = proposal?.documents || [];
        if (idx > -1) {
          arr.splice(idx, 1);
        }

        setProposal({ ...proposal, documents: arr });
      },
    });
  };

  const props = {
    className: documentsError ? 'error' : '',
    name: 'file',
    multiple: true,
    action: BASE_API_URL + '/api/file-service/v1/files',
    beforeUpload: (file) => {
      const isTypeAllowed =
        file.type === 'application/pdf' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/svg' ||
        file.type === 'image/png';
      if (!isTypeAllowed) {
        // message.error(`${file.name} is not a pdf file`);
        notification.error({
          message: 'Kesalahan',
          description: `Dokumen yang dapat di-upload adalah Dokumen PDF/JPG/JPEG/SVG/PNG`,
        });
      }

      const isSizeAllowed = file.size <= 100 * 1024 * 1024;
      if (!isSizeAllowed) {
        notification.error({
          message: 'Kesalahan',
          description: `Ukuran Dokumen yang di Upload Melebihi 100MB`,
        });
      }

      return (isTypeAllowed && isSizeAllowed) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);

        console.log(info.file, info.fileList);
        setDocuments(info.fileList);
        setDocumentsError(false);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onFinish = (values, nav_btn) => {
    const payload = {
      draft: nav_btn === 'list' || nav_btn === 'back',
    };

    if (documents.length < 1 && proposal.documents.length < 1) {
      if (nav_btn === 'back') {
          return navigate(
            `/proposal-submission/create-proposal/step/3/${proposalId}`
          );
      }
      
      setDocumentsError(true);
      return;
    }

    let docs = [];
    documents.forEach((item) => {
      let doc = item.response?.data;
      if (doc) {
        docs.push({
          fileId: doc.id,
          fileName: doc.name,
          fileSize: doc.size,
          mimeType: doc.mimeType,
          path: doc.key,
        });
      }
    });

    if (proposal?.documents) {
      proposal?.documents.forEach((item) => {
        docs.push({ ...item });
      });
    }

    payload.documents = [...docs];

    setLoading(true);
    axios
      .put(`${BASE_URL}/proposal/${proposalId}/step/4`, payload)
      .catch((err) => {
        let errorMessage;
        let errorMessages = [];

        if (
          err.response.data.errors === undefined ||
          err.response.data.errors.length === 0
        ) {
          errorMessage = err.response.data.message;
        } else {
          <ul style={{ listStyleType: 'disc' }}>
            {err.response.data.errors.forEach((error) => {
              errorMessages.push(<li>&#x2022; {error.message}</li>);
            })}
          </ul>;
        }
        notification.error({
          message: 'Kesalahan',
          duration: 5,
          description: errorMessage ? errorMessage : errorMessages,
        });
        setLoading(false);
        if (nav_btn === 'back') {
          return navigate(
            `/proposal-submission/create-proposal/step/3/${proposalId}`
          );
        }
      })
      .then((res) => {
        setLoading(false);
        if (res) {
          if (nav_btn === 'back') {
            return navigate(
              `/proposal-submission/create-proposal/step/3/${proposalId}`
            );
          } else if (nav_btn === 'list') {
            return navigate(`/proposal-submission`);
          } else {
            notification.success({
              message: 'Data berhasil disubmit!',
              duration: 5,
              description: 'Data yang anda masukan sudah berhasil disubmit.	',
            });
            return navigate('/proposal-submission');
          }
        }
      });
  };

  const onColDocChange = (checked) => {
    setColDoc(checked);
  };

  return (
    <div id="top">
      <Spin spinning={loading} indicator={spinIcon}>
        <div className="px-4">
          <Form form={form} name="form-create-donor" onFinish={onFinish}>
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLine} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mt-2 mr-3">
                  Daftar Proposal Submission
                </p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex justify-center items-center mt-2"
                >
                  <span className="font-medium text-center">
                    Ajukan Proposal
                  </span>
                </Button>
              </div>
            </div>
            <div className="mb-4" style={{ marginBottom: '40px' }}>
              <div div className="flex" style={{ marginLeft: '150px' }}>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EEFFF4',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#00BD52',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Informasi General
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EEFFF4',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#00BD52',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Informasi Project
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EEFFF4',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#00BD52',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Direktorat & Donor
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #00BD52',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Dokumen Pendukung
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="warning-box">
              <WarningOutlined style={{ fontSize: 20, marginRight: 10 }} />
              <p>
                Silahkan isi semua data di bawah ini untuk bisa melanjutkan
                pengisian ke tahap selanjutnya
              </p>
            </div>

            <div
              className="w-100 list overflow-hidden mb-[92px] lg:mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Dokumen pendukung</p>
              </div>
              <hr />
              {colDoc ? (
                <div className="px-5">
                  <p
                    className="text-2xl field-required font-medium mt-4"
                    style={{ color: '#01A24A' }}
                  >
                    Upload Dokumen Pendukung
                  </p>
                  <span className="" style={{ color: '#828282' }}>
                    *Bisa upload lebih dari satu
                  </span>
                  {proposal?.documents &&
                    proposal?.documents.map((doc, idx) => (
                      <div
                        className="flex justify-between mr-4"
                        style={{
                          border: '1px solid #EAECF0',
                          borderRadius: '12px',
                          padding: '16px',
                          width: '335px',
                          height: '96px',
                        }}
                      >
                        <div className="mr-4">
                          <Icon component={DownloadAbleIcon} />
                        </div>

                        <div className="row mr-10">
                          <p className="wrap-text bold">{doc.fileName} </p>{' '}
                          <span>({doc.fileSize}KB)</span>
                          <div className="flex justify-between">
                            <strong
                              style={{ color: '#00BD52', cursor: 'pointer' }}
                              onClick={() => {
                                window.open(doc.url, '_blank');
                              }}
                            >
                              Buka
                            </strong>
                            <strong
                              style={{ color: '#00BD52', cursor: 'pointer' }}
                              onClick={() => {
                                downloadFile(doc.url, doc.fileName);
                              }}
                            >
                              Unduh
                            </strong>
                          </div>
                        </div>

                        <div
                          className="ml-4"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleDeleteDocument(idx);
                          }}
                        >
                          <Icon component={DeleteIcon} />
                        </div>
                      </div>
                    ))}
                  <div className="row mt-4 mb-4">
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon flex justify-center">
                        {/* <InboxOutlined /> */}
                        <UploadIcon />
                      </p>
                      <p className="ant-upload-text">
                        <strong style={{ color: '#01A24A' }}>
                          Klik untuk upload
                        </strong>{' '}
                        atau drag and drop
                      </p>
                      <p className="ant-upload-hint">
                        PDF, SVG, PNG, JPG, JPEG, or GIF (max.100mb)
                      </p>
                    </Dragger>
                    {documentsError && (
                      <div class="ant-form-item-explain ant-form-item-explain-connected" role="alert">
                        <div class="ant-form-item-explain-error">Wajib Diisi</div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div
              className="mt-6 px-4"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
                position: 'fixed',
                bottom: 0,
                width: 'calc(100vw - 380px)',
              }}
            >
              <div className="flex justify-between items-center">
                <Space>
                  {/* <NavLink to={`/proposal-submission/create-proposal/step/3/${params.id}`}> */}
                  <Button
                    style={{
                      borderRadius: '8px',
                      border: '1px solid #EAECF0',
                      width: '120px',
                      height: '50px',
                    }}
                    icon={<ArrowLeftOutlined />}
                    className="flex justify-center items-center font-medium"
                    onClick={() => {
                      onFinish(form.getFieldsValue(), 'back');
                    }}
                  >
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                  {/* </NavLink> */}
                </Space>
                <div className="row">
                  <Form.Item>
                    <Space>
                      {/* <NavLink to={'/proposal-submission'}> */}
                      {/* <Button
                        style={{
                          borderRadius: '8px',
                          border: '1px solid #D0D5DD',
                          width: '160px',
                          height: '52px',
                        }}
                        icon={<SaveOutlined />}
                        className="flex justify-center items-center font-medium"
                        onClick={() => {
                          onFinish(form.getFieldsValue(), 'list');
                        }}
                      >
                        <span className="ml-2 text-center">Simpan & Draft</span>
                      </Button> */}
                      {/* </NavLink> */}
                    </Space>
                    <Space>
                      <Button
                        style={{
                          background: '#00BD52',
                          color: '#FFFF',
                          borderRadius: '8px',
                        }}
                        htmlType="submit"
                        className="btn-submit mt-4 ml-4"
                      >
                        <span className="text-center">Submit</span>
                      </Button>
                    </Space>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default CreateProposalSubmissionStepFour;
