import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from "html-to-pdfmake";

pdfMake.vfs = pdfFonts.vfs;

export default function ProposalModal(props) {
    const [pdfUrl, setPdfUrl] = useState();

    // function getBase64ImageFromURL(url) {
    //     return new Promise((resolve, reject) => {
    //         var img = new Image();
    //         img.setAttribute("crossOrigin", "anonymous");

    //         img.onload = () => {
    //             var canvas = document.createElement("canvas");
    //             canvas.width = img.width;
    //             canvas.height = img.height;

    //             var ctx = canvas.getContext("2d");
    //             ctx.drawImage(img, 0, 0);

    //             var dataURL = canvas.toDataURL("image/png");

    //             resolve(dataURL);
    //         };

    //         img.onerror = error => {
    //             reject(error);
    //         };

    //         img.src = url;
    //     });
    // }

    const getBase64ImageFromURL = async (url) => {
        let blob = await fetch(url).then(r => r.blob());
        let dataUrl = await new Promise(resolve => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });

        return dataUrl
    };

    // const [imageRef, setImageRef] = useState([]);
    var imageRef = {}
    const htmlContent = async (val) => {
        let content = htmlToPdfmake(val);

        let isImageContent = false;
        if (content && content.length > 0) {
            content?.forEach((_content, idxContent) => {
                if (_content?.stack) {
                    _content?.stack?.forEach(async (_stack) => {
                        if (_stack.nodeName === "IMG") {
                            isImageContent = true;
                        }
                    })
                }

                if ((_content?.style ?? []).includes('ql-indent-1')) {
                    _content.margin[0] = 12
                }

                _content.alignment = "justify"
                _content.lineHeight = 1.15
            })

            if (isImageContent) {
                let imgParser = htmlToPdfmake(val, {
                    imagesByReference: true
                });

                content = imgParser.content;
                content?.forEach((_content, idxContent) => {
                    _content?.stack?.forEach(async (_stack, idxStack) => {
                        if (_stack.nodeName === "IMG") {
                            content[idxContent].stack[idxStack].width = 300;
                            content[idxContent].stack[idxStack].alignment = "center";
                            isImageContent = true;
                        }
                    })
                })

                if (imgParser) {
                    imageRef = Object.assign(imageRef, imgParser.images);
                }
            }

            // console.log("new format: ", content)
            return content;
        } else {
            return ""
        }
    }

    const handleArrayValue = (arr) => {
        let string;
        arr?.forEach((item, idx) => {
            if (idx > 0) {
                string = string + ", " + item;
            } else {
                string = item;
            }
        })

        return string;
    }

    const generatePDF = async () => {
        let p = props.proposal;
        let gi = props.proposal?.generalInformation;

        let page1Data = [
            ['Organization Name', gi?.organizationName],
            ['Address', gi?.address],
            ['City, State, Postal Code', gi?.city + (gi?.district ? ", " + gi?.district : "") + (gi?.zipcode ? ", " + gi?.zipcode : "")],
            ['Country', gi?.country],
            ['Phone', gi?.phoneNumber],
            ['Email', gi?.email],
            ['Website', gi?.website],
        ];

        p?.director.forEach((director, idx) => {
            page1Data.push([`CEO/Executive Director ${idx + 1} Name`, director.name]);
            page1Data.push([`CEO/Executive Director ${idx + 1} Title`, director.position])
            page1Data.push([`CEO/Executive Director ${idx + 1} Email`, director.email || "-"])
        });

        page1Data.push([' ', ' ']);
        page1Data.push(['Primary Contact', p?.contactPerson?.name]);
        page1Data.push(['Address', p?.contactPerson?.address]);
        page1Data.push(['Phone', p?.contactPerson?.phoneNumber]);
        page1Data.push(['Email', p?.contactPerson?.email]);

        page1Data.push([' ', ' ']);
        page1Data.push(['Total Project Budget', p?.submissionFunding?.funding]);
        page1Data.push(['Proposed Project Period', p?.submissionFunding?.dp]);
        page1Data.push(['Proposed Grand Term', p?.submissionFunding?.grant_period + p?.submissionFunding?.grant_period_type]);

        gi?.objectives.forEach((obj, idx) => {
            if (idx < 1) {
                page1Data.push([{
                    rowSpan: gi?.objectives.length,
                    text: 'Proposal Objective'
                }, obj]);
            } else {
                page1Data.push(['', obj]);
            }
        })

        page1Data.push([' ', ' ']);
        page1Data.push(['Donor', p?.donor?.donor]);
        page1Data.push(['Phone', (p?.donor?.phoneNumbers ?? []).join(', ')]);
        page1Data.push(['Email', (p?.donor?.email ?? []).join(', ')]);
        page1Data.push(['Website', p?.donor?.website]);
        page1Data.push(['Source of Funds', p?.donor?.sourceOfFunds]);

        const programPriorities = (p?.projectPriorityPrograms ?? []).reduce((accumulator, program) => {
            const programOutcomeOutputs = [
                [{ text: "Outcome", alignment: "center" }, { text: "Output", alignment: "center" }],
            ];

            program.outcomes.forEach((programOutcome) => {
                if (!programOutcome?.outputs || programOutcome.outputs.length === 0) {
                    programOutcomeOutputs.push([programOutcome.linkageOutput?.outputName], "-");
                    return;
                }

                programOutcome.outputs.forEach((op, idx) => {
                    const existOutputIdx = programOutcomeOutputs.findIndex(([output]) => output === programOutcome.linkageOutput?.outputName);
                    if (idx === 0 && existOutputIdx < 0) {
                        programOutcomeOutputs.push([programOutcome.linkageOutput?.outputName || "", { text: op.linkageSubOutput?.subOutputName || "", alignment: "justify" }]);
                        return;
                    }

                    const existSubOutputIdx = programOutcomeOutputs.findIndex(([_, suboutput]) => suboutput.text === op.linkageSubOutput?.subOutputName);
                    if (existSubOutputIdx < 0) {
                        programOutcomeOutputs.push([" ", { text: op.linkageSubOutput?.subOutputName || "", alignment: "justify" }]);
                    }
                });
            })
            
            return accumulator.concat([
                { text: "Project Goals", fontSize: 12, bold: true },
                { text: program.programGoals, fontSize: 12 },
                { text: ' ' },

                {
                    // layout: 'lightHorizontalLines', 
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        // headerRows: 1,
                        widths: ['*', '*'],

                        body: programOutcomeOutputs,
                        style: 'tableStyle',
                    }
                },
                { text: ' ' },
            ]);
        }, []);

        const documentDefinition = {
            // A string or { width: number, height: number }
            pageSize: 'A4',

            // By default we use portrait, you can change it to landscape if you wish
            // pageOrientation: 'landscape',

            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [20, 30, 20, 30],

            content: [
                { text: gi?.title || 'Proposal Title', fontSize: 14, alignment: "center", bold: true },
                { text: ' ' },
                {
                    // layout: 'lightHorizontalLines', 
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        // headerRows: 1,
                        widths: ['*', '*'],

                        body: page1Data,
                        style: 'tableStyle',
                    }
                },
                { text: '', pageBreak: 'after' },

                p?.organizationBackground && { text: "Organization Background (IEF)", fontSize: 14, bold: true },
                p?.organizationBackground && { text: ' ' },
                p?.organizationBackground && await htmlContent(p?.organizationBackground),

                p?.needs && { text: "Needs", fontSize: 14, bold: true },
                p?.needs && { text: ' ' },
                p?.needs && await htmlContent(p?.needs),

                p?.organizationalLandscape && { text: "Organizational Landscape", fontSize: 14, bold: true },
                p?.organizationalLandscape && { text: ' ' },
                p?.organizationalLandscape && await htmlContent(p?.organizationalLandscape),

                { text: "Project Description", fontSize: 14, bold: true },
                { text: ' ' },

                ...programPriorities,

                p?.investmentCriteria && { text: "Investment Criteria", fontSize: 12, bold: true },
                p?.investmentCriteria && { text: handleArrayValue(p?.investmentCriteria), fontSize: 12 },
                p?.investmentCriteria && { text: ' ' },

                p?.financialInstrument && { text: "Financial Instrument", fontSize: 12, bold: true },
                p?.financialInstrument && { text: handleArrayValue(p?.financialInstrument), fontSize: 12 },
                p?.financialInstrument && { text: ' ' },

                p?.distribution?.allocationMechanism && { text: "Allocation Mechanism", fontSize: 12, bold: true },
                p?.distribution?.allocationMechanism && { text: handleArrayValue(p?.distribution?.allocationMechanism), fontSize: 12 },
                p?.distribution?.allocationMechanism && { text: ' ' },

                p?.distribution?.distributionMechanism && { text: "Distribution Mechanism", fontSize: 12, bold: true },
                p?.distribution?.distributionMechanism && { text: handleArrayValue(p?.distribution?.distributionMechanism), fontSize: 12 },
                p?.distribution?.distributionMechanism && { text: ' ' },

                p?.distribution?.location && { text: "Location", fontSize: 12, bold: true },
                p?.distribution?.location && { text: handleArrayValue(p?.distribution?.location), fontSize: 12 },
                p?.distribution?.location && { text: ' ' },

                p?.distribution?.beneficiary && { text: "Beneficiaries", fontSize: 12, bold: true },
                p?.distribution?.beneficiary && { text: handleArrayValue(p?.distribution?.beneficiary), fontSize: 12 },
                p?.distribution?.beneficiary && { text: ' ' },

                { text: '', pageBreak: 'after' },

                p?.projectStructure && { text: "Project Structure", fontSize: 14, bold: true },
                p?.projectStructure && { text: ' ' },
                p?.projectStructure && await htmlContent(p?.projectStructure),
                p?.projectStructure && { text: ' ' },
                
                p?.principleObstacle && { text: "Risk Management", fontSize: 14, bold: true },
                p?.principleObstacle && { text: ' ' },
                p?.principleObstacle && await htmlContent(p?.principleObstacle),
                p?.principleObstacle && { text: ' ' },
                
                p?.safeguards && { text: "Safeguards", fontSize: 14, bold: true },
                p?.safeguards && { text: ' ' },
                p?.safeguards && await htmlContent(p?.safeguards),
                p?.safeguards && { text: ' ' },
                
                p?.communicatingResult && { text: "Communication Strategy", fontSize: 14, bold: true },
                p?.communicatingResult && { text: ' ' },
                p?.communicatingResult && await htmlContent(p?.communicatingResult),
                p?.communicatingResult && { text: ' ' },
                
                { text: '', pageBreak: 'after' },
                
                p?.others && { text: "Others Information", fontSize: 14, bold: true },
                p?.others && { text: ' ' },
                p?.others && await htmlContent(p?.others),
                p?.others && { text: ' ' },

                // { // HTML content
                //     stack: [
                //         { text: ReactHtmlParser(p?.organizationBackground), fontSize: 14, bold: true },
                //         { canvas: [{ type: 'rect', x: 0, y: 0, w: 400, h: 100 }] } // Example canvas element
                //     ]
                // },
            ],

            images: imageRef,

            styles: {
                "ql-indent-1": {
                    margin: [36, 0, 0, 0]
                }
            },

            tableStyle: {
                // bold: true,
                fontSize: 10,
                // color: 'black'
            }
        };

        // Generate PDF
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        // Fetch the blob containing the PDF data
        pdfDocGenerator.getBlob((blob) => {
            const newPdfUrl = URL.createObjectURL(blob);
            setPdfUrl(newPdfUrl);
        });
    };

    useEffect(() => {
        if (props.proposal) {
            generatePDF();
        }
    }, [props]);

    return (
        <div>
            <Modal
                title={<div style={{ borderBottom: "solid 1px #BDBDBD", paddingBottom: 5, fontWeight: "bold", fontSize: 20 }}>
                    Lihat Proposal
                </div>}
                onCancel={() => props.setOpenModal(false)}
                centered
                open={props.openModal}
                width={"75%"}
                style={{ bottom: 50 }}
                footer={null}
            >
                <div>
                    {pdfUrl && (
                        <div>
                            <iframe title="PDF Preview" src={pdfUrl} width="100%" height="720px" />
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};