/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import ReactQuill, { Quill } from "react-quill";

import EditorToolbar from "../../molecules/EditorToolbar";

import "./styles.css";

class PreserveWhiteSpace {
  constructor(quill) {
    quill.container.style.whiteSpace = "pre-line";
  }
}

Quill.register("modules/preserveWhiteSpace", PreserveWhiteSpace);

export const CustomQuill = ({ Form, form, disable, name, data, rtfIndex }) => {
  const reactQuillRef = useRef(null); // Ref for ReactQuill component

  const [state, setState] = React.useState({ value: data?.name || form.getFieldValue(name) });
  const handleChange = (value) => {
    console.log(value)
    setState({ value: value })
    form.setFieldValue(name, value);
  };

  React.useEffect(() => {
    if (data) {
      setState({ value: form.getFieldValue(name) })
    } else {
      setState({ value: form.getFieldValue(name) })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form.getFieldsValue(true)])

  const modules =
  {
    preserveWhiteSpace: true,
    toolbar: {
      container: `#toolbar${rtfIndex}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
    },
  }

  function insertSymbol(symbol) {
    const cursorPosition = reactQuillRef?.current?.editor?.getSelection()?.index || 0;
    reactQuillRef.current.editor?.insertText(cursorPosition, symbol);
    reactQuillRef.current.editor?.setSelection(cursorPosition + 1);
  }

  return (
    <Form.Item name={name}>
      <div className={`text-editor`} id={name}>
        <EditorToolbar name={name} rtfIndex={rtfIndex} disable={disable} />

        {!disable &&
          <div className="ql-toolbar ql-snow">
            <button type="button" className="btn-quill" onClick={() => insertSymbol("∆")}>
              ∆
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("∑")}>
              ∑
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("∞")}>
              ∞
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("≈")}>
              ≈
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("≡")}>
              ≡
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("±")}>
              ±
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("√")}>
              √
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("Ω")}>
              Ω
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("π")}>
              π
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("α")}>
              α
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("β")}>
              β
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("µ")}>
              µ
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("˃")}>
              ˃
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("˂")}>
              ˂
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("≥")}>
              ≥
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("≤")}>
              ≤
            </button>

            <button type="button" className="btn-quill" onClick={() => insertSymbol("$")}>
              $
            </button>

            <button type="button" className="btn-quill" onClick={() => insertSymbol("¢")}>
              ¢
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("£")}>
              £
            </button>
            <button type="button" className="btn-quill" onClick={() => insertSymbol("¥")}>
              ¥
            </button>
          </div>
        }
        <ReactQuill
          id={name}
          ref={reactQuillRef}
          theme="snow"
          readOnly={disable}
          style={{ background: disable ? '#EFF0F1' : '#FFFF', cursor: disable ? 'not-allowed' : '', whiteSpace: "pre-wrap" }}
          modules={modules}
          value={state.value || ""}
          onChange={handleChange}
          preserveWhitespace={true}
        />
      </div>

    </Form.Item>
  );
};

export default CustomQuill;