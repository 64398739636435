import React from "react";
import { useNavigate } from "react-router";
import { UserOutlined } from '@ant-design/icons';
import { Button, Popover } from "antd";

import store from "app/store";

const Header = () => {
  const navigate = useNavigate();

  const oidc_user = store.getState().oidc.oidc_user;
  const user = oidc_user?.profile;

  const onLogout = () => {
    navigate('/logout');
  };

  return (
    <nav
      className="navbar border-b top-0"
      style={{ zIndex: 99, background: "#FFF", position: "fixed", width: "calc(100vw - 20rem)" }}
    >
      <div className="flex-1">
        {/* <a className="btn btn-ghost normal-case text-xl">CMS</a> */}
      </div>
      <div className="flex-none gap-2">
        <div className="flex justify-between dropdown dropdown-end">
          {/* <div className="flex items-center space-x-2">
            <div>
              <svg
                aria-hidden="true"
                class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
            </div>
            <div>
              <svg
                aria-hidden="true"
                class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
            </div>
            <div>
              <svg
                aria-hidden="true"
                class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
            </div>
            <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src="https://placeimg.com/80/80/people" />
              </div>
            </label>
          </div> 
          */}
          <ul
            tabIndex="0"
            className="m-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box"
          >
            <li style={{ cursor: "pointer" }}>
              <b>Portal Menu Penghimpunan & Pengembangan Dana</b>
            </li>
          </ul>

          <div className="flex justify-between item-center">
            {/* <ul
              tabIndex="0"
              className="m-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box text-red"
            >
              <li style={{ cursor: "pointer" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M7.79447 17.5003C8.38208 18.019 9.15395 18.3337 9.99932 18.3337C10.8447 18.3337 11.6166 18.019 12.2042 17.5003M14.9993 6.66699C14.9993 5.34091 14.4725 4.06914 13.5349 3.13146C12.5972 2.19378 11.3254 1.66699 9.99932 1.66699C8.67324 1.66699 7.40147 2.19378 6.46379 3.13146C5.52611 4.06914 4.99932 5.34091 4.99932 6.66699C4.99932 9.24215 4.34972 11.0053 3.62404 12.1715C3.01193 13.1552 2.70587 13.6471 2.71709 13.7843C2.72952 13.9362 2.76171 13.9942 2.88414 14.085C2.99471 14.167 3.49315 14.167 4.49004 14.167H15.5086C16.5055 14.167 17.0039 14.167 17.1145 14.085C17.2369 13.9942 17.2691 13.9362 17.2816 13.7843C17.2928 13.6471 16.9867 13.1552 16.3746 12.1715C15.6489 11.0053 14.9993 9.24215 14.9993 6.66699Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </li>
            </ul> */}

            <Popover
              sizePopupArrow={0}
              trigger="click" placement="bottomRight"
              content={<div style={{ display: "block", textAlign: "center" }}>
                  <div>
                    <b>{user?.Name}</b>
                  </div>
                  <div className="capitalize">
                    {user?.Jabatan} <br />
                    {user?.Directorat}
                  </div>
                  {/* <Popover trigger="click" placement="bottom"
                  content={<Select
                    style={{ width: 200 }}
                    placeholder={OIDC?.profile?.Jabatan + "- " + OIDC?.profile?.Directorat?.toUpperCase()}
                    options={[
                      { label: "Staff PPD", value: "staff-ppd" },
                      { label: "Direktur PPD", value: "direktur-ppd" },
                      { label: "Staff KL", value: "staff-kl" },
                      { label: "Direktur KL", value: "direktur-kl" },
                      { label: "Direktorat Lain", value: "direktorat-lain" },
                      { label: "Direktur Utama", value: "direktur-utama" },
                    ]}
                    onChange={(val) => {
                      let oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${'web_app_internal'}`))
                      let role = val.split("-");
                      oidcStorage = {
                        ...oidcStorage,
                        profile: {
                          ...oidcStorage.profile,
                          Jabatan: role[0],
                          Directorat: role[1]
                        }
                      }
                      sessionStorage.setItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${'web_app_internal'}`, JSON.stringify(oidcStorage));

                      window.location.reload()
                    }}
                  />}
                >
                  <Button>
                    Change Role
                  </Button>
                </Popover> */}
                <Button style={{ color: "red" }}
                    onClick={() => {
                      onLogout();
                    }}
                  >
                    Logout
                  </Button>
              </div>}
            >
              <ul
                tabIndex="0"
                className="m-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box text-red"
                style={{ borderRadius: "50%", width: 50, textAlign: "center", cursor: "pointer" }}
              ><UserOutlined /></ul>
            </Popover>
          </div>

        </div>
      </div>
    </nav >
  );
};

export default Header;
